import { cloneDeep, isEmpty, set } from "lodash";
import { all, call, fork, put, select, take, takeLatest } from "redux-saga/effects";
import {
  type Flow,
  type FlowAsset,
  type FlowAssetMeta,
  type FlowDetails,
  type FlowEmailMeta,
  type FlowEndInfo,
  FlowStatus,
  type Progress,
  type ProgressRecord,
} from "../../types";
import { selectAssets, selectFlow } from "./flowSelectors";
import {
  assetErr,
  completeAssessmentAsset,
  completePdfAsset,
  completeSurveyAsset,
  completeVideoAsset,
  err,
  finishProgressUpdate,
  got,
  gotAssetDetails,
  gotAssets,
  redirect,
  registerOnEventSessionInFlow,
  req,
  reqAssetDetails,
  restartFlow as restartFlowAction,
  skip,
  startProgressUpdate,
  updateFlow,
} from "./flowSlice";

import { type AssessmentQuestion, type SurveyQuestion } from "../../../Assets/types";

import {
  ClientUserSessionRegistrationSuccess,
  ClientFlowStarted,
} from "../../../Application/services/realTimeNotification/events/libraryEvents";
import { beginAsyncOperation } from "../../../Application/slices/asyncOperationSlice";
import { takeEveryWithQueue } from "../../../common/helpers/sagaHelpers";
import { EntityType, type FlowEntity, FlowEntityType } from "../../../common/types";
import { ErrorTypes } from "../../../types/ErrorTypes";
import HttpCodes from "../../../types/HttpCodes";
import {
  completeSurveyForFlow,
  evaluateAssessmentForFlow,
  fetchAssessmentQuestionsById,
  fetchEventAssetData,
  fetchFlow,
  fetchFlowProgress,
  fetchPdfAssetData,
  fetchSurveyQuestionsById,
  fetchVideoAssetData,
  getContent,
  registerOnEventSessionInFlow as registerOnEventSessionInFlowRequest,
  restartFlow,
  setFlowPdfViewed,
  setFlowVideoWatched,
  skipAsset,
  startFlow,
} from "../../services/flowsService";
import { createRtnChannel } from "features/Application/services/realTimeNotification/rtnChannel";

export function* handleFetchFlow(action: ReturnType<typeof req>) {
  let progressRecords: ProgressRecord[] = [];
  let flow: Flow;
  let progress: Progress;
  try {
    flow = yield call(fetchFlow, action.payload.id, action.payload.dontCopy);
    if (flow.id !== action.payload.id) {
      yield put(redirect(flow.id));
      return;
    }
  } catch (error: any) {
    yield handleError(error, ErrorTypes.Flow);
    return;
  }
  try {
    progress = yield call(fetchFlowProgress, action.payload.id);
    progressRecords = getRecordsToDisplay([], progress?.records);
    yield put(
      got({
        current: { flow, progress: { ...progress, records: progressRecords } },
      }),
    );
  } catch (error: any) {
    yield handleError(error, ErrorTypes.Progress);
    return;
  }
  try {
    if (action.payload.startFlowActive && (progress.status === FlowStatus.NotStarted || isEmpty(progress))) {
      const chan = createRtnChannel([ClientFlowStarted], 10_000); // skips handling if not received in 10 seconds
      yield fork(startFlow, action.payload.id);
      yield take(chan);
    }
  } catch (error: any) {
    yield handleError(error, ErrorTypes.StartFlow);
    return;
  }

  if (isEmpty(progress)) {
    return;
  }

  try {
    const assets: FlowAssetMeta[] = yield getAssets(progressRecords, action.payload.dontCopy, flow.flowEndInfos);
    yield put(gotAssets({ assets }));
  } catch (error: any) {
    yield handleError(error, ErrorTypes.Assets);
  }
}

export function* handleVideoWatched(action: ReturnType<typeof completeVideoAsset>) {
  const { assetId, flowId } = action.payload;
  try {
    yield call(setFlowVideoWatched, flowId, assetId);
  } catch (error: any) {
    yield handleError(error, ErrorTypes.Assets);
  }
}

export function* handlePdfViewed(action: ReturnType<typeof completePdfAsset>) {
  const { assetId, flowId } = action.payload;
  try {
    yield call(setFlowPdfViewed, flowId, assetId);
  } catch (error: any) {
    yield handleError(error, ErrorTypes.Assets);
  }
}

export function* handleAssessmentEvaluate(action: ReturnType<typeof completeAssessmentAsset>) {
  const { assetId, data, flowId } = action.payload;
  try {
    yield call(evaluateAssessmentForFlow, flowId, assetId, data);
  } catch (error: any) {
    yield handleError(error, ErrorTypes.Assets);
  }
}

export function* handleCompleteSurvey(action: ReturnType<typeof completeSurveyAsset>) {
  const { surveyId, data, flowId } = action.payload;
  try {
    yield call(completeSurveyForFlow, flowId, surveyId, data);
  } catch (error: any) {
    yield handleError(error, ErrorTypes.Assets);
  }
}

export function* handleRegistrationOnEventSession(action: ReturnType<typeof registerOnEventSessionInFlow>) {
  const { eventId, flowId, sessionId, sessionStartDate, registrationType } = action.payload;
  try {
    yield put(
      beginAsyncOperation({
        id: eventId,
        action: ClientUserSessionRegistrationSuccess,
      }),
    );

    yield call(registerOnEventSessionInFlowRequest, {
      eventId,
      flowId,
      sessionId,
      sessionStartDate,
      registrationType,
    });
  } catch (error: any) {
    yield handleError(error, ErrorTypes.Assets);
  }
}

export function* handleProgressUpdate(action: ReturnType<typeof startProgressUpdate>) {
  // Any errors in the function shouldn't be caused by invalid permissions
  // (which are handled by handleFetchFlow)
  try {
    const flowDetails: FlowDetails = yield select(selectFlow);
    const progressRecords = getRecordsToDisplay([], action.payload.progress.records);
    if (flowDetails.progress.records.length === progressRecords.length) {
      const oldAssets: FlowAssetMeta[] = yield select(selectAssets);
      yield put(
        finishProgressUpdate({
          assets: oldAssets,
          progress: { ...action.payload.progress, records: progressRecords },
        }),
      );
      return;
    }

    const assets: FlowAssetMeta[] = yield getAssets(
      progressRecords,
      action.payload.dontCopy,
      flowDetails.flow.flowEndInfos,
    );

    yield put(
      finishProgressUpdate({
        assets,
        progress: { ...action.payload.progress, records: progressRecords },
      }),
    );
  } catch (error: any) {
    yield handleError(error, ErrorTypes.Assets);
  }
}

function* getAssets(progressRecords: ProgressRecord[], dontCopy: boolean, endInfo?: FlowEndInfo[]) {
  const typesToFetch = new Set<string>([
    EntityType.Video,
    EntityType.Assessment,
    EntityType.Message,
    EntityType.Survey,
    EntityType.Event,
    EntityType.Email,
    EntityType.Pdf,
  ]);

  const allItems: FlowAssetMeta[] = yield select(selectAssets);
  const savedAssets: FlowAssetMeta[] = allItems.filter(
    x => x.type !== FlowEntityType.UnavailableAsset && typesToFetch.has(x.type),
  );
  const toFetch = getEntitiesToFetch(progressRecords, typesToFetch).filter(
    x => !savedAssets.some(y => y.id === Number(x.id) && y.type === x.type),
  );

  const flowEnd = getFlowEndRecord(progressRecords, FlowEntityType.FlowEnd);

  let entities: FlowAssetMeta[], flowEndAsset: FlowAssetMeta[];

  [entities, flowEndAsset] = yield all([call(getContent, toFetch), call(getFlowEndInfo, flowEnd, endInfo, dontCopy)]);

  const assets: FlowAssetMeta[] = combineAssets(
    mapAssets([...entities, ...savedAssets, ...flowEndAsset]),
    progressRecords,
  );

  return assets;
}

export function* handleGetAssetData(action: ReturnType<typeof reqAssetDetails>) {
  try {
    const asset = action.payload.asset;
    let assetWithData: FlowAsset;
    switch (asset.type) {
      case EntityType.Video: {
        const data: FlowAsset = yield call(fetchVideoAssetData, asset.id);
        assetWithData = { ...asset, ...data };
        break;
      }
      case EntityType.Assessment: {
        const questions: AssessmentQuestion[] = yield call(fetchAssessmentQuestionsById, asset.id);
        assetWithData = { ...asset, questions };
        break;
      }
      case EntityType.Survey: {
        const surveyQuestions: SurveyQuestion[] = yield call(fetchSurveyQuestionsById, asset.id);
        assetWithData = { ...asset, questions: surveyQuestions };
        break;
      }
      case EntityType.Pdf: {
        const pdfData: FlowAsset = yield call(fetchPdfAssetData, asset.id);
        assetWithData = { ...asset, ...pdfData };
        break;
      }
      case EntityType.Event:
        const eventData: FlowAsset = yield call(fetchEventAssetData, asset.id);
        assetWithData = { ...asset, ...eventData };
        break;
      default:
        assetWithData = asset;
    }
    yield put(gotAssetDetails({ asset: assetWithData }));
  } catch (error: any) {
    yield error.response?.status === HttpCodes.Forbidden
      ? handleAssetError(error, action.payload.asset)
      : handleError(error, ErrorTypes.Assets);
  }
}

export function* handleSkip(action: ReturnType<typeof skip>) {
  const flowDetails: FlowDetails = yield select(selectFlow);
  yield call(skipAsset, flowDetails.flow.id, action.payload.assetId, action.payload.assetType);
}

export function* handleRestartFlow(action: ReturnType<typeof restartFlowAction>) {
  try {
    yield call(restartFlow, action.payload.id);
    const flow: Flow = yield call(fetchFlow, action.payload.id, action.payload.dontCopy);
    yield put(updateFlow(flow));
  } catch (error: any) {
    yield handleError(error, ErrorTypes.Flow);
  }
}

export function* flowWatcherSaga() {
  yield takeLatest(req.type, handleFetchFlow);
  yield takeLatest(reqAssetDetails.type, handleGetAssetData);
  yield takeLatest(completeVideoAsset.type, handleVideoWatched);
  yield takeLatest(completeAssessmentAsset.type, handleAssessmentEvaluate);
  yield takeLatest(completeSurveyAsset.type, handleCompleteSurvey);
  yield takeLatest(registerOnEventSessionInFlow.type, handleRegistrationOnEventSession);
  yield takeLatest(restartFlowAction.type, handleRestartFlow);
  yield takeLatest(completePdfAsset.type, handlePdfViewed);
  yield takeEveryWithQueue(skip, handleSkip);
  yield takeEveryWithQueue(startProgressUpdate, handleProgressUpdate);
}

function combineAssets(assetsMap: { [key: string]: FlowAssetMeta }, records: ProgressRecord[]) {
  let assets: FlowAssetMeta[] = [];

  for (let record of records) {
    const asset = assetsMap[`${record.key}:${record.id}`];
    if (asset) {
      assets[record.order] = asset;
    } else {
      assets[record.order] = {
        originType: record.key,
        id: Number(record.id),
        type: FlowEntityType.UnavailableAsset,
        title: "",
      };
    }
  }
  return assets;
}

export function* getFlowEndInfo(
  flowEnd: ProgressRecord | undefined,
  flowEndInfos: FlowEndInfo[] | undefined,
  dontCopy: boolean,
) {
  if (isEmpty(flowEnd)) return [];
  const endInfo = flowEndInfos?.find(info => info.id === flowEnd?.uid);

  let nextFlow: Flow | undefined;
  let statusCode: number | undefined;
  if (endInfo?.nextFlowId) {
    try {
      nextFlow = yield call(fetchFlow, endInfo?.nextFlowId, dontCopy);
      statusCode = HttpCodes.Succeeded;
    } catch (e: any) {
      statusCode = e.response.status;
    }
  }
  return [
    {
      id: flowEnd.id,
      completedMessage: endInfo?.completedMessage ?? "",
      nextFlowId: nextFlow?.id,
      type: FlowEntityType.FlowEnd,
      title: nextFlow?.title ?? "",
      statusCode: statusCode,
    },
  ];
}

function getEntitiesToFetch(records: ProgressRecord[], types: Set<string>) {
  return records.filter(r => types.has(r.key)).map(r => ({ type: r.key, id: r.id }));
}

function getFlowEndRecord(records: ProgressRecord[], type: FlowEntity) {
  return records.find(r => r.key === type);
}

function getRecordsToDisplay(typesToExclude: FlowEntity[], records?: ProgressRecord[]) {
  let progressRecords: ProgressRecord[] = [];
  let order = 0;
  records?.forEach(record => {
    if (!typesToExclude.includes(record.key)) {
      progressRecords.push({ ...record, order });
      order++;
    }
  });

  return progressRecords;
}

const handleError = (error: any, type: ErrorTypes) =>
  put(err({ error: { message: error?.message, code: error.response?.status, type } }));

const handleAssetError = (error: any, asset: FlowAssetMeta) =>
  put(assetErr({ asset: asset, message: error?.response.data, code: error?.response?.status }));

function normalizeEmail(asset: FlowAssetMeta) {
  const normalizedEmail = cloneDeep(asset) as FlowEmailMeta;
  normalizedEmail.title = normalizedEmail.subject;
  return normalizedEmail;
}

const normalize = (asset: FlowAssetMeta) => {
  const normalizeAssetMap: {
    [key in FlowEntity]?: (asset: FlowAssetMeta) => FlowAssetMeta;
  } = {
    [EntityType.Email]: normalizeEmail,
  };

  const result = normalizeAssetMap[asset.type]?.(asset) ?? asset;

  return { ...result, type: asset.type };
};

const mapAssets = (items: FlowAssetMeta[]) => {
  const result = {};
  items.forEach(x => {
    set(result, `${x.type}:${x.id}`, normalize(x));
  });
  return result;
};
