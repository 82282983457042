import EventEmitter from "events";
import { type Progress, ProgressStatus } from "../types";
import { useCallback } from "react";

type Actions =
  | { type: "ProgressUpdated"; payload: Progress }
  | { type: "ResetSubmitted"; payload?: never }
  | { type: "ResetPerformed"; payload?: never };

type Types = Actions["type"];
type PayloadOf<T extends Types> = {
  [K in Types]: Extract<Actions, { type: K }>;
}[T]["payload"];

const createProgressEmitter = () => {
  const progressEmitter = new EventEmitter();
  let lastTimestamp = "";

  return {
    raise(action: Actions) {
      // prevent outdated updates
      if (action.type === "ProgressUpdated") {
        if (lastTimestamp >= action.payload.timestamp) {
          return;
        }
        lastTimestamp = action.payload.timestamp;
      }

      progressEmitter.emit(action.type, "payload" in action ? action.payload : undefined);
    },
    subscribe<T extends Types>(type: T, cb: (payload: PayloadOf<T>) => void) {
      progressEmitter.on(type, cb);
    },
    unsubscribe<T extends Types>(type: T, cb: (payload: PayloadOf<T>) => void) {
      progressEmitter.off(type, cb);
    },
    dispose() {
      progressEmitter.removeAllListeners();
    },
  };
};

type ProgressEmitter = ReturnType<typeof createProgressEmitter>;

const emitterBuilder = (): ((flow: number | undefined) => ProgressEmitter) => {
  let currentFlow: number | undefined;
  let emitter: ProgressEmitter = createProgressEmitter();

  return (flow: number | undefined) => {
    if (currentFlow !== flow) {
      currentFlow = flow;
      emitter.dispose();
      emitter = createProgressEmitter();
      applyResetHandler(emitter);
    }

    return emitter;
  };
};

export const getOrCreateEmitter = emitterBuilder();

export const useProgressEmitter = (flow: number | undefined) => {
  const emitter = getOrCreateEmitter(flow);
  const subscribe = useCallback(
    <T extends Types>(type: T, cb: (payload: PayloadOf<T>) => void) => {
      emitter.subscribe(type, cb);
      return () => emitter.unsubscribe(type, cb);
    },
    [emitter],
  );

  const raise = useCallback(
    (action: Actions) => {
      emitter.raise(action);
    },
    [emitter],
  );

  return [subscribe, raise] as const;
};

const applyResetHandler = (emitter: ProgressEmitter) => {
  let hasBeenSubmitted = false;
  emitter.subscribe("ResetSubmitted", () => {
    hasBeenSubmitted = true;
  });

  emitter.subscribe("ProgressUpdated", (payload: Progress) => {
    const firstItemStatus = payload.records?.[0]?.status;

    if (firstItemStatus === ProgressStatus.Active && hasBeenSubmitted) {
      hasBeenSubmitted = false;
      emitter.raise({ type: "ResetPerformed" });
    }
  });
};
