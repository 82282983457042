import { type EventSession } from "features/Events/types";
import { useCallback, useEffect, useState } from "react";

export const useSessionRegistrationLimits = (session: EventSession, limitEventRegistrationEnabled: boolean) => {
  const [registeredCount, setRegisteredCount] = useState<number>(0);

  useEffect(() => {
    setRegisteredCount(session.userRegistrationCount ?? 0);
  }, [session.userRegistrationCount]);

  const registrationDisabled =
    limitEventRegistrationEnabled && registeredCount === session.registrationLimit && !session.wasRegistered;

  const sessionRegistrationInfo =
    session.registrationLimit && session.registrationLimit > 0
      ? `${session.registrationLimit - registeredCount} / ${session.registrationLimit} available`
      : "";

  const incrementRegistrationCount = useCallback(() => {
    setRegisteredCount(prev => prev + 1);
  }, []);

  const decrementRegistrationCount = useCallback(() => {
    setRegisteredCount(prev => Math.max(prev - 1, 0));
  }, []);

  return { registrationDisabled, sessionRegistrationInfo, incrementRegistrationCount, decrementRegistrationCount };
};
